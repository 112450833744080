import google_play from 'images/download/google-play.png';
import app_store from 'images/download/app-store.png';
import qr_code from 'images/download/download_gratibum_QR_code.png';
import { useTranslation } from 'react-i18next';

const DownloadDescription = () => {
    const { t } = useTranslation();

    const redirectToStore = ( url ) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return (
        <div className="download_description_buttons__div">
            <div className="download_description__div">
                <p>
                    { t('download_desc') }
                </p>
            </div>

            <div className="download_buttons__div">
                <div className="playstore_applestore__div">
                    <div className="playstore_button__div"
                        onClick={ () => redirectToStore("https://play.google.com/store/apps/details?id=com.vvs.gratibum")}
                    >
                        <img src={google_play} alt="" />
                        <p>{ t('get_it_on') } Google PlayStore</p>
                    </div>

                    <div className="appstore_button__div"
                        onClick={ () => redirectToStore("https://apps.apple.com/us/app/gratibum/id1635816534") }
                    >
                        <img src={app_store} alt="" />
                        <p>{ t('get_it_on') } AppStore</p>
                    </div>

                </div>

                <p className='or_p'>or</p>

                <div className="qr_code__div">
                    <img 
                        src={qr_code}
                        alt="qr code" 
                    />
                </div>
            </div>
        </div>
    );
}
 
export default DownloadDescription;