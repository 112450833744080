import { makeObservable, observable, action } from "mobx";

class UserStore {
    user = {
        name: "",
        email: "",
        authId: "",
        accountId: "",
    }
    
    constructor() {
        makeObservable(this, {
            user: observable,
            login: action,
            logout: action
        });
    }

    login(user) {
        this.user.name = user.name;
        this.user.email = user.email;
        this.user.authId = user.authId;
        this.user.accountId = user.accountId;
    }

    logout() {
        this.user.name = "";
        this.user.email = "";
        this.user.authId = "";
        this.user.accountId = "";
    }
}

const userStore = new UserStore()
export default userStore;