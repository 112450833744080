import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const SelectLanguage = () => {
  const [language, setLanguage] = useState("en");

  const { t } = useTranslation();

  function toggleLanguages() {
    if (language === "en") {
      setLanguage("ro");
      i18next.changeLanguage("ro");
    } else {
      setLanguage("en");
      i18next.changeLanguage("en");
    }
  }

  return (
    <div onClick={toggleLanguages} className="select_language__div">
      <p>{ t('change_language') }</p>
      <img
        alt={language === "en" ? "RO" : "US"}
        width={16}
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${
          language === "en" ? "RO" : "US"
        }.svg`}
      />
    </div>
  );
};

export default SelectLanguage;
