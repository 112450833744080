import GratibumLogoWithText from 'components/site/sections/hero_section/GratibumLogoWithText';
import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LoginResponses, LoginService } from 'services/login_service';

import login_illustration from '../../images/svgs/login_illustration.svg';
import google from '../../images/icons/social media/google.svg';
// import apple from '../../images/svgs/apple.svg';

const LoginPage = () => {
    // const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [ emailError, setEmailError ] = useState("");
    const [ passwordError, setPasswordError ] = useState("");

    const emailTextChanged  = event => {
        let email = event.target.value;
        setEmail(email);
    };

    const passwordTextChanged  = event => {
        let pass = event.target.value;
        setPassword(pass);
      };

    const loginWithEmailAndPassword = async () => {
        setEmailError("");
        setPasswordError("");

        const response = await new LoginService().loginWithEmailAndPassword( email, password );

        if ( response !== LoginResponses.success ) {
            switch ( response ) {

                case LoginResponses.email_format_error:
                    setEmailError("Please enter a valid email format ( username@gmail.com )");
                    break;

                case LoginResponses.incorrect_password_error:
                    setPasswordError("Your password was incorrect");
                    break;

                case LoginResponses.inexistent_account_error:
                    setEmailError("There is no account linked with this email.");
                    break;

                default:
                    setPasswordError("An unexpected error occured :(");
                    break;
            }
        } else {
            navigate('/manage-account');
        }

    }

    const loginWithGoogle = async () => {
        setEmailError("");
        setPasswordError("");

        const response = await new LoginService().loginWithGoogle();

        if ( response !== LoginResponses.success ) {
            if ( response === LoginResponses.inexistent_account_error ) {
                setPasswordError("There is no account linked to this email.")
            } else {
                setPasswordError("An error occured while logging in with Google.")
            }
        } else {
            navigate('/manage-account');
        }
    }

    // const loginWithApple = async () => {
    //     setEmailError("");
    //     setPasswordError("");

    //     const response = await new LoginService().loginWithApple();

    //     if ( response !== LoginResponses.success ) {
    //         setPasswordError("An error occured while logging in with Apple.")
    //     } else {
    //         navigate('/manage-account');
    //     }
    // }
    
    return (
        <div className="login_screen">
            <GratibumLogoWithText />

            <div className="content__div">
                <div className="illustration__div">

                    <img width={404} height={404} src={login_illustration} alt="" />
                </div>

                <div className="login_form__div">
                    <div className="info">
                        <h3>Hey there! :)</h3>
                        <p>
                            To continue, please log into your GratiBum account.
                        </p>
                    </div>

                    <div className="input_fields">

                        <div className="email_input">
                            <input 
                                onChange={emailTextChanged}
                                type="text" 
                                placeholder='jojo@mail.com'
                            ></input>

                            { emailError !== "" &&
                                <p className='error_msg'>
                                    {emailError}
                                </p>
                            }
                        </div>

                        <div className="password_input">
                            <input
                                onChange={passwordTextChanged}
                                type="password" 
                                placeholder='******'
                            />

                            { passwordError !== "" &&
                                <p className='error_msg'>
                                    { passwordError }
                                </p>
                            }
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="login_button"
                            onClick={loginWithEmailAndPassword}
                        >
                            <p>Login</p>
                        </div>

                        <p>or</p>
                        
                        <span>
                            <img
                                onClick={loginWithGoogle}
                                width={32} height={32} src={google} alt="" 
                            />

                            {/* <img
                                onClick={loginWithApple}
                                src={apple} alt="" 
                            /> */}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LoginPage;
