import React from 'react'

function MenuItem({ title, selected, onClick }) {
    const onItemClick = () => {
        onClick();
    }

  return (
    <div className={ selected ? 'menu_item selected' : 'menu_item' }>
        <p
            onClick={onItemClick}
        >
            { title }
        </p>
</div>

  )
}

export default MenuItem