import LandingPage from './pages/site/LandingPage';

import 'styles/style.css';

import { 
  BrowserRouter as Router, 
  Routes, 
  Route 
} from 'react-router-dom';

import DownloadPage from './pages/site/download';
import LoginPage from 'pages/gratibum/LoginPage';
import ManageAccount from 'pages/site/ManageAccount';

function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
          <Route
            key="landingPage"
            path=''
            element={ <LandingPage/> }
          ></Route>

          <Route
             path='/login'
            element={ <LoginPage /> }
          ></Route>

          <Route
             path='/manage-account'
            element={ <ManageAccount /> }
          ></Route>

          <Route
             path='/download'
            element={ <DownloadPage /> }
          ></Route>
        </Routes>
    </Router>
    </div>
  );
}

export default App;
