import DownloadDescription from "components/site/sections/download_section/DownloadDescription";
import DownloadScreens from "components/site/sections/download_section/DownloadScreens";
import GratibumLogoWithText from "components/site/sections/hero_section/GratibumLogoWithText";
import { useTranslation } from "react-i18next";

const DownloadSection = () => {
    const { t } = useTranslation();

    return (
        <div className="download_section__div">
            <div className="logo__div">
                <GratibumLogoWithText />
            </div>

            <div className="main_content__div">
                <div className="download_headline__div">
                    <h1>
                        { t('download_headline') }
                    </h1>
                </div>

                <div className="download_description_buttons_screens__div">
                    <DownloadDescription />
                    <DownloadScreens />
                </div>
            </div>

            <span></span>
        </div>
    );
}
 
export default DownloadSection;