const FeatureItem = ( props ) => {
    const { 
        onClick,
        index, 
        className, 
        itemTitle, 
        image 
    } = props;
    
    return (
        <div className = {`feature_icon_item ${className}`}
            onClick={ () => onClick(index) }
            style={{
                backgroundImage:`url(${image})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <h1>{itemTitle}</h1>
        </div>
    );
}
 
export default FeatureItem;