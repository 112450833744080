import ItemInfo from 'components/site/manage_account/ItemInfo';
import MenuItem from 'components/site/manage_account/MenuItem';
import GratibumLogoWithText from 'components/site/sections/hero_section/GratibumLogoWithText';
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import AccountService from 'services/account_service'
import { auth } from 'services/firebase'
import { observer } from 'mobx-react';
import userStore from 'stores/user.store';
import LocalStorageService, { LOCALSTORAGE_IDS } from 'services/local_storage_service';

function ManageAccount() {
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(null);
    const [ feedback, setFeedback ] = useState("");
    const [ feedbackColor, setFeedbackColor ] = useState("");
    
    const [ currentIndex, setCurrentIndex ] = useState(0);

    const signOut = async () => {
        await new AccountService().signOut();
        new LocalStorageService().removeFromLocalStorage(LOCALSTORAGE_IDS.USER_FROM_FIRESTORE);
        userStore.logout();

        navigate('/login');
    }

    const deleteAccount = async () => {
        setLoading(true);

        const resp = await new AccountService().markAccountForDeletion(userStore.user.accountId);

        if ( resp.status === "success" ) {
            setFeedback("Your account has been marked for deletion and will be deleted in 30 days. If you change your mind, either log into the GratiBum app, or recover it from here. :)")
            setFeedbackColor("green");
        } else {
            setFeedback("Something went wrong. Please try again later.")
            setFeedbackColor("red");
        }
        
        setLoading(false);

        setTimeout( async () => {
            await signOut();
            navigate('/login');
        }, 5000);
    }

    const resetDeleteAccount = async () => {
        setLoading(true);

        const resp = await new AccountService().resetMarkForDeletion(userStore.user.accountId);

        if ( resp.status === "success" ) {
            setFeedback("Your account has been recovered. Welcome back to GratiBum! ❤️")
            setFeedbackColor("green");
        } else {
            setFeedback("Something went wrong. Please try again later.")
            setFeedbackColor("red");
        }

        setLoading(false);

        setTimeout( async () => {
            setCurrentIndex(0);
            setLoading(null);
        }, 5000);
    }

    if ( new LocalStorageService().getItemFromLocalStorage(LOCALSTORAGE_IDS.USER_FROM_FIRESTORE) === undefined || new LocalStorageService().getItemFromLocalStorage(LOCALSTORAGE_IDS.USER_FROM_FIRESTORE) === null ) {
        return <Navigate to='/login' replace />
    } else {
        const user_from_localstorage = JSON.parse(new LocalStorageService().getItemFromLocalStorage(LOCALSTORAGE_IDS.USER_FROM_FIRESTORE))
        userStore.login(user_from_localstorage);
    }


    const menu_items = [
        {
            index: 0,
            title: "Account Info",
            headline: `Dashboard of ${userStore.user.name}'s account`,
            description: "This is the dashboard of your GratiBum app, where you can either Remove or Restore your account.",
            call_to_action: "Logout",
            action: signOut
        },

        {
            index: 1,
            title: "Remove Account",
            headline: "Are you sure you want to delete your account?",
            description: "This action will mark the account for removal. It will be permanently deleted after 30 days. If you want to restore it, log into your GratiBum app before that time.",
            call_to_action: "Remove account",
            action: deleteAccount
        },

        {
            index: 2,
            title: "Reset Delete Account",
            headline: "Are you sure you want to keep your account?",
            description: "Doing this will mark your account to not be deleted.",
            call_to_action: "Recover account",
            action: resetDeleteAccount
        },

        // {
        //     index: 3,
        //     title: "Logout",
        //     headline: "Are you sure you want to logout?",
        //     description: "You can login again anytime.",
        //     call_to_action: "Logout",
        //     action: signOut
        // },
    ]

    return (
        <div
            className='manage_account_screen'
        >
            <GratibumLogoWithText />

            <div className="content__div">
                <div className="menu">
                    {
                        menu_items.map( ( item ) => <MenuItem
                            key={item.index}
                            title={item.title}
                            onClick={ () => {
                                setCurrentIndex(item.index );
                            }}

                            selected={ currentIndex === item.index }

                        />
                        )
                    }
                </div>

                {
                    loading === null
                        ? (
                            <ItemInfo 
                                headline={menu_items[currentIndex].headline}
                                description={menu_items[currentIndex].description}
                                call_to_action={menu_items[currentIndex].call_to_action}
                                action={menu_items[currentIndex].action}
                            />
                        )

                        : (
                            loading === true
                                ? <h1>LOADING...</h1>
                                : <div className="info">
                                    <p
                                    style={{
                                        width: "100%",
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: feedbackColor
                                    }}
                                >{feedback}</p>
                                    </div>
                        )
                }
            </div>
        </div>
    )
}

export default observer(ManageAccount)