import React, { useState } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
import IllustrationLeftPart from 'components/site/sections/why_gratibum_section/Illustration_left_part';
import DetailsRightPart from 'components/site/sections/why_gratibum_section/DetailsRightPart';

const WhyGratibumSection = () => {
    const [ index, setIndex ] = useState(0);
    const [ direction, setDirection ] = useState(0);
    
    const sliderLength = 3;
  
    const handlePrevious = () => {
      setIndex((prevIndex) => (prevIndex === 0 ? sliderLength - 1 : prevIndex - 1));
      setDirection(-1);
    };
  
    const handleNext = () => {
      setIndex((prevIndex) => (prevIndex === sliderLength - 1 ? 0 : prevIndex + 1));
      setDirection(1);
    };

    return (
        <div className="why_gratibum_section__div">
            <IllustrationLeftPart 
                handlePrevious={handlePrevious}
                index={index}
                handleNext={handleNext}    
                direction={direction}
            />

            <DetailsRightPart
                index={index}
                direction={direction}
            />
        </div>
    );
}

export default WhyGratibumSection