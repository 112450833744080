import logo from 'images/gratibum_logo.png';

const GratibumLogoWithText = () => {
    return (
        <div className='gratibum_logo_with_text__div'>
            <img src={logo} alt="" />
            <h3>GratiBum</h3>
        </div>
    );
}
 
export default GratibumLogoWithText;