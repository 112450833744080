import ios_spalsh_screen from 'images/screens/ios_splash_screen.png';
import android_notification from 'images/screens/android_notification.png';
import android_gratibum_details from 'images/screens/android_gratibum_details.png';

const DownloadScreens = () => {
    return (
        <div className="download_screens__div">
            <img 
                src={android_notification}
                alt="android notification screen"
            />

            <img 
                src={ios_spalsh_screen}
                alt="ios splash screen"
            />

            <img 
                src={android_gratibum_details}
                alt="android gratibum deatails"
            />
        </div>
    );
}
 
export default DownloadScreens;