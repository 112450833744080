import ios_gratibums_list from 'images/screens/ios_gratibums_list.png';
import android_gratibum_details from 'images/screens/android_gratibum_details.png';

const AppScreensRightPart = () => {
    return (
        <div className='app_screens_right_part__div'>
           <div>
                <img src={ios_gratibums_list} alt="iOS Screen: list of gratibums" />
           </div>

           <div>
                <img src={android_gratibum_details} alt="Android Screen: gratibum details" />
           </div>
        </div>
    )
}

export default AppScreensRightPart;