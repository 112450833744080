import React from "react"

const DownloadPage = () => {
    console.log('USER AGENT: ' + navigator.userAgent);

    var userAgentString = navigator.userAgent;

    if (userAgentString.indexOf("iPhone") > -1 || userAgentString.indexOf("iPod") > -1 || userAgentString.indexOf("iPad") > -1) {
        window.location.replace('https://apps.apple.com/us/app/gratibum/id1635816534');
    } else if (/Android/.test(userAgentString)) {
        window.location.replace('https://play.google.com/store/apps/details?id=com.vvs.gratibum');
        // window.location.href = "market://details?id=com.vvs.gratibum";
    } else {
        window.location.replace('https://gratibum.com');
    }

    return (
        <div />
    );
}

export default DownloadPage