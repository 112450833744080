const LOCALSTORAGE_IDS = {
    USER_FROM_FIRESTORE: "USER_FROM_FIRESTORE"
}

class LocalStorageService {
    saveToLocalStorage(id, value){
        localStorage.setItem(id, JSON.stringify(value));
    }

    getItemFromLocalStorage(id) {
        return localStorage.getItem(id);
    }

    removeFromLocalStorage(id) {
        localStorage.removeItem(id);
    }
}

export { LOCALSTORAGE_IDS }
export default LocalStorageService