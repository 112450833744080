import { useTranslation } from "react-i18next"

const CallToActionHeroSection = () => {
    const { t } = useTranslation();

    const scrollToDownloadSection = () => {
        document.getElementsByClassName("why_gratibum_section__div")[0].scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className="cta_hero_section__div"
            onClick={() => scrollToDownloadSection() }
        >
            <p className="cta_hero_section__p">
                { t('hero_section_cta') }
            </p>
        </div>
    )
}

export default CallToActionHeroSection;