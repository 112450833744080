import { db } from "./firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
const { auth } = require("./firebase");

class AccountService {
    
    async getAccountFromFirestore( email, uid ) {
        const accountsCollectionRef = collection(db, "accounts");
        const found_accounts = query(
            accountsCollectionRef,
            where("email", "==", email),
            where("authId", "==", uid)
        );

        const querySnapshot = await getDocs(found_accounts);

        if ( querySnapshot.empty) {
            return null;
        }

        return querySnapshot.docs[0].data()
    }

    async markAccountForDeletion( user_uid ) {
        const BASE_URL = process.env.REACT_APP_BACKEND_PROD_API;

        const token = await auth.currentUser.getIdToken();

        try {
            const response = await fetch(
                `${BASE_URL}/account/${user_uid}/markForDeletion`,
                {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                }
            );
    
            const resp = await response.json();
    
            return resp; 
        } catch (error) {
            throw error;
        }
    }

    async resetMarkForDeletion( user_uid ) {
        const BASE_URL = process.env.REACT_APP_BACKEND_PROD_API;

        const token = await auth.currentUser.getIdToken();

        try {
            const response = await fetch(
                `${BASE_URL}/account/${user_uid}/resetMarkForDeletion`,
                {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                }
            );
    
            const resp = await response.json();

            return resp;
        } catch (error) {
            throw error;
        }
    }

    async signOut() {
        await auth.signOut();
    }
}

export default AccountService;