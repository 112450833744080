import CallToActionHeroSection from "./CallToActionHeroSection";
import GratibumLogoWithText from "./GratibumLogoWithText";

import { useTranslation } from "react-i18next";

const WhiteBgLeftPart = () => {
    const { t } = useTranslation();

    return (
        <div className="white_bg_left_part__div">
          
            <GratibumLogoWithText />

            {/* heading */}
            <h1 className="hero_section_heading__h1">
                { t('hero_section_headline') }
            </h1>

            {/* subheading */}
            <h3 className="hero_section_subheading__h3">
                { t('hero_section_subheading') }
            </h3>

            {/* CTA */}
            <CallToActionHeroSection />
        </div>
    )
}

export default WhiteBgLeftPart;