import GratibumLogoWithText from "components/site/sections/hero_section/GratibumLogoWithText";

import facebook from 'images/social_media/facebook.png';
import instagram from 'images/social_media/instagram.png';
import tiktok from 'images/social_media/tiktok.png';
import kofi from 'images/social_media/kofi.png';
import { Link } from "react-router-dom";
import SelectLanguage from "components/site/SelectLanguage";

import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    const handleDownloadClick = () => {
        var userAgentString = navigator.userAgent;

        if (userAgentString.indexOf("iPhone") > -1 || userAgentString.indexOf("iPod") > -1 || userAgentString.indexOf("iPad") > -1) {
            window.location.replace('https://apps.apple.com/us/app/gratibum/id1635816534');
        } else if (/Android/.test(userAgentString)) {
            window.location.replace('https://play.google.com/store/apps/details?id=com.vvs.gratibum');
            // window.location.href = "market://details?id=com.vvs.gratibum";
        } else {
            document.getElementsByClassName("download_section__div")[0].scrollIntoView({ behavior: 'smooth' })
        }    
    }

    return (
        <div className="footer__div">
            <div className="content">
                <div className="logo__div">
                    <div className="span"/>
                    <GratibumLogoWithText />
                </div>

                <div className="get_app__div">
                    <h3>
                        { t('become_member') }
                    </h3>

                    <div className="download_button__div"
                        onClick={() => handleDownloadClick() }
                    >
                        <p>
                            { t('download') }
                        </p>
                    </div>
                </div>

                <div className="social_media__div">
                    <h3>Social Media</h3>
                    <div className="rows__div">
                            <Link
                                to="https://www.facebook.com/gratibum"
                                target="_blank"
                            >
                                <img 
                                    src={facebook}
                                    alt="facebook icon"
                                />
                            </Link>

                            <Link
                                to="https://www.instagram.com/gratibumgratitudealbum/"
                                target="_blank"
                            >
                                <img 
                                    src={instagram}
                                    alt="instagram icon"
                                />
                            </Link>

                            <Link
                                to="https://www.tiktok.com/@gratibum4"
                                target="_blank"
                            >
                                <img 
                                    src={tiktok}
                                    alt="tiktok icon"
                                />
                            </Link>

                            <Link
                                to="https://ko-fi.com/gratibum"
                                target="_blank"
                            >
                                <img 
                                    src={kofi}
                                    width={32}
                                    alt="kofi icon"
                                />
                            </Link>
                    </div>

                    <div className="change_language__div">
                      <SelectLanguage />
                    </div>
                </div>
            </div>


            <div className="copyright">
                <p>
                    © Copyright 2023, MagiCode
                </p>
            </div>
        </div>
    );
}
 
export default Footer;