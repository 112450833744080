import FeatureItem from "components/site/sections/features_section/FeatureItem";
import GratibumLogoWithText from "components/site/sections/hero_section/GratibumLogoWithText";

import ios_center_screen_blob from 'images/screens/ios_gratibums_list.png';
import android_create_gratibum from 'images/screens/android_create_gratibum.png';
import android_notification from 'images/screens/android_notification.png';

import capture from 'images/illustrations/features/capture.png';
import illustrate from 'images/illustrations/features/illustrate.png';
import remind from 'images/illustrations/features/remind.png';
import { useState } from "react";

import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";

const FeaturesSection = () => {
    const [ fullScreen, setFullScreen ] = useState(false);
    const [ selectedIndex, setSelectedIndex ] = useState(0);

    const { t } = useTranslation();

    const features_screens = [
        ios_center_screen_blob,
        android_create_gratibum,
        android_notification
    ];

    const features_texts = [
        {
            title: t('capture_title'),
            description: t('capture_desc')
        },

        {
            title: t('illustrate_title'),
            description: t('illustrate_desc')
        },
        {
            title: t('remind_title'),
            description: t('remind_desc')
        },
    ]

    const handleItemClick = ( index ) => {
        setFullScreen( true );
        setSelectedIndex(index);
    }

   const handleChangeFeatureDisplay = ( index ) => {
        setSelectedIndex(index);
    }

    return ( 
        <div className="features_section__div">
            <div className="logo__div">
                <GratibumLogoWithText />
                <h3>Features</h3>
            </div>

                <div className="center_blob__div"
                    style={{
                        display: fullScreen ? "none" : "flex"
                    }}
                >
                    <img src={ios_center_screen_blob} alt="" />
                </div>

                <div className="features_icons__div"
                    style={{
                        display: fullScreen ? "none" : "block"
                    }}
                >

                    <FeatureItem
                        onClick = { () => handleItemClick(0) }
                        index = "0"
                        className = "capture__div"
                        itemTitle = {t('capture')}
                        image = {capture}
                    />

                    <FeatureItem
                        onClick = { () => handleItemClick(1) }
                        index = "1"
                        className = "illustrate__div"
                        itemTitle = {t('illustrate')}
                        image = {illustrate}
                    />

                    <FeatureItem
                        onClick = { () => handleItemClick(2) }
                        index = "2"
                        className = "remind__div"
                        itemTitle = {t('remind')}
                        image = {remind}
                    />
                </div>

                <motion.div className="features_display"
                    initial = {{
                        opacity: 0
                    }}

                    animate = {{
                        opacity: fullScreen ? 1 : 0
                    }}

                    transition={{
                        duration: 1
                    }}

                    style={{
                        display: fullScreen ? "flex" : "none"
                    }}
                >
                    <div className="feature_column features_icons_details__div">
                        <FeatureItem
                            onClick = {() =>handleChangeFeatureDisplay(0)}
                            index = "0"
                            className = {`capture_details_item__div ${ selectedIndex === 0 ? "selected" : "" }`}
                            itemTitle = {t('capture')}
                            image = {capture}
                        />

                        <FeatureItem
                            onClick = {() => handleChangeFeatureDisplay(1) }
                            index = "1"
                            className = {`illustrate_details_item__div ${ selectedIndex === 1 ? "selected" : "" }`}
                            itemTitle = {t('illustrate')}
                            image = {illustrate}
                        />

                        <FeatureItem
                            onClick = {() => handleChangeFeatureDisplay(2)}
                            index = "2"
                            className = {`remind_details_item__div ${ selectedIndex === 2 ? "selected" : "" }`}
                            itemTitle = {t('remind')}
                            image = {remind}
                        />
                    </div>

                    <div className="screen_and_text__div">
                        <div className="feature_column app_screens__div">
                            <img src={features_screens[selectedIndex]} alt="" />
                        </div>

                        <div className="feature_column text_details__div">
                            <h3>
                                { features_texts[selectedIndex].title }
                            </h3>
                            <p>
                                { features_texts[selectedIndex].description }
                            </p>
                        </div>
                    </div>

                </motion.div>
            </div>
    );
}
 
export default FeaturesSection;