import AppScreensRightPart from "components/site/sections/hero_section/AppScreensRightPart";
import WhiteBgLeftPart from "components/site/sections/hero_section/WhiteBgLeftPart";

const HeroSection = () => {
    return ( 
        <div className="hero_section">
            <WhiteBgLeftPart />
            <AppScreensRightPart />
        </div>
     );
}
 
export default HeroSection;