import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const DetailsRightPart = ( props ) => {
    const { index, direction } = props;

    const { t } = useTranslation();

    const texts = [
        {
            title: t('boost_positivity_title'),
            description: t('boost_positivity_desc')
        },
        
        {
            title: t('capture_moments_title'),
            description: t('capture_moments_desc')
        },

        {
            title: t('find_joy_title'),
            description: t('find_joy_desc')
        },
    ];

    const variants = {
        initial: ( direction ) => {
           return {
               y: direction > 0 ? -200 : 200,
               opacity: 0,
           }
        },

        animate: {
           y: 0,
           opacity: 1,
           transition: 'ease-in'
        },

        exit: ( direction ) => {
           return {
               y: direction < 0 ? 200 : -200,
               opacity: 0
           }
        }
   }

    return ( 
        <AnimatePresence custom={direction}>
            <div className="deatils_right_part__div">
                    <motion.div className="text__div"
                        variants={variants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        key={texts[index].title}
                        custom={direction}
                    >
                        <h3>
                            { texts[index].title }
                        </h3>

                        <p>
                            { texts[index].description }
                        </p>
                    </motion.div>
            </div>
        </AnimatePresence>
    );
}
 
export default DetailsRightPart;