import React from 'react'

function ItemInfo( {headline, description, call_to_action, action } ) {
  return (
    <div className="info">
                    {/* delete account info */}

                    <div className="text">
                        <h1 className="headline">
                            { headline }
                        </h1>

                        <p className="description">
                            { description }
                        </p>
                    </div>

                    <div className="buttons">
                        <div className="cancel">
                            <p>
                                Cancel
                            </p>
                        </div>

                        <div className="cta"
                            onClick={action}
                        >
                            <p>
                                { call_to_action }
                            </p>
                        </div>
                    </div>
                </div>
  )
}

export default ItemInfo