import { deleteUser, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleAuthProvider } from "./firebase";
import AccountService from "./account_service";

import userStore from "stores/user.store";
import LocalStorageService, { LOCALSTORAGE_IDS } from "./local_storage_service";

const LoginResponses = {
    success: "SUCCESS",
    email_format_error: "EMAIL_FORMAT_ERROR",
    incorrect_password_error: "INCORECT_PASSWORD",
    inexistent_account_error: "INEXISTENT_ACCOUNT_ERROR",
    unexpected_error: "UNEXPECTED_ERROR"
}

class LoginService {

    validateEmail = ( email_to_validate ) => {
        return String( email_to_validate )
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    async loginWithEmailAndPassword( email, password ) {

        if (!this.validateEmail(email)) {
            return LoginResponses.email_format_error;
        }

        try {
            const userCredentials = await signInWithEmailAndPassword(auth, email, password);

            const user_from_firestore = await new AccountService().getAccountFromFirestore(userCredentials.user.email, userCredentials.user.uid);

            if ( user_from_firestore === null ) {
                await deleteUser(auth.currentUser); // delete the created auth user
                return LoginResponses.inexistent_account_error;
            }

            new LocalStorageService().saveToLocalStorage(LOCALSTORAGE_IDS.USER_FROM_FIRESTORE, user_from_firestore);

            userStore.login(user_from_firestore);

            return LoginResponses.success;
        } catch (error) {
            switch (error.code) {
                case 'auth/user-not-found':
                    return LoginResponses.inexistent_account_error;

                case 'auth/wrong-password':
                    return LoginResponses.incorrect_password_error;

                default:
                    return LoginResponses.unexpected_error;
            }
        }
    }

    async loginWithGoogle() {
        try {
            const result = await signInWithPopup(auth, googleAuthProvider);
            const user = result.user;
    
            if (!user.uid || typeof user.uid !== "string") {
                throw new Error("Invalid user UID");
            }
                
            const user_from_firestore = await new AccountService().getAccountFromFirestore(user.email, user.uid);

            if ( user_from_firestore === null ) {
                await deleteUser(auth.currentUser); // delete the created auth user
                return LoginResponses.inexistent_account_error;
            }

            new LocalStorageService().saveToLocalStorage(LOCALSTORAGE_IDS.USER_FROM_FIRESTORE, user_from_firestore);

            userStore.login(user_from_firestore);

            return LoginResponses.success;
        } catch (error) {
            return LoginResponses.unexpected_error;
        }
    }
}

export { LoginService, LoginResponses };