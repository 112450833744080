import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';

import GratibumLogoWithText from "../hero_section/GratibumLogoWithText";

import { AnimatePresence, motion } from 'framer-motion';

import boost_positivity from 'images/illustrations/why_gratibum/boost_positivity.png';
import capture_moments from 'images/illustrations/why_gratibum/capture_moments.png';
import find_joy_in_reflection from 'images/illustrations/why_gratibum/find_joy_in_reflection.png';

const IllustrationLeftPart = (props) => {
    const { handlePrevious, index, handleNext, direction } = props;

    const illustrations = [
        boost_positivity,
        capture_moments,
        find_joy_in_reflection
    ];

    const variants = {
         initial: ( direction ) => {
            return {
                y: direction > 0 ? 200 : -200,
                opacity: 0,
            }
         },

         animate: {
            y: 0,
            opacity: 1,
            transition: 'ease-in'
         },

         exit: ( direction ) => {
            return {
                y: direction < 0 ? -200 : 200,
                opacity: 0
            }
         }
    }

    return ( 
        <div className="illustration_left_part">
            <div className="logo__div">
                <h3>Why</h3>
                <GratibumLogoWithText />
            </div>

            <AnimatePresence
                custom={direction}
            >
                <div className="illustration__div">
                    <motion.img 
                        variants={variants}          
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        src={illustrations[index]}
                        alt="illustration"
                        key={illustrations[index]}
                        custom={direction}
                    />
                </div>
            </AnimatePresence>

            <div className="navigation__div">
                <IoIosArrowDropleftCircle 
                    onClick={handlePrevious}
                    size={36}
                    style={{
                        cursor: "pointer"
                    }}
                />

                <p>{ index + 1 } / { illustrations.length }</p>

                <IoIosArrowDroprightCircle 
                    onClick={handleNext}
                    size={36} 
                    style={{
                        cursor: "pointer"
                    }}
                />
            </div>
        </div>
    );
}
 
export default IllustrationLeftPart;