import React, { Suspense } from 'react'

import HeroSection from 'pages/site/sections/HeroSection';
import WhyGratibumSection from './sections/WhyGratibumSection';
import FeaturesSection from './sections/FeaturesSection';
import DownloadSection from './sections/DownloadSection';
import Footer from 'pages/site/sections/Footer';

const LandingPage = () => {
    return (
        <Suspense fallback={<h1>Loading...</h1>}>
            <HeroSection />
            <WhyGratibumSection />
            <FeaturesSection />
            <DownloadSection />
            <Footer />
        </Suspense>
    )
}

export default LandingPage
